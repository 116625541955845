import * as React from "react";
import * as msal from "@azure/msal-browser";
import { Stack, PrimaryButton, MessageBar, MessageBarType } from '@fluentui/react';
import { fetchMsGraph, graphConfig, loginRequest, msalConfig } from './AuthenticationConfig';
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { Icon } from '@fluentui/react/lib/Icon';
import HttpService from "../../dataprovider/HttpService";
import { IDataProvider } from "../../dataprovider/IDataProvider";
import { IApplicationSettings, IAuthenticationSettings } from "../../Models";
import { Providers, ProviderState, SimpleProvider } from '@microsoft/mgt-element'

const LockIcon = () => <Icon style={{ marginRight: '5px', fontSize: '25px' }} iconName="LockSolid" />;

interface AuthenticatedComponentProps {
    dataProvider: IDataProvider;
}

interface AuthenticatedComponentState {
    account: any | null,
    token: any | null,
    error: any | null,
    isUserAuthenticated: boolean
    isAuthenticating: boolean
}

export default (AuthenticatedComponent: any, componentProps: { applicationSettings: IApplicationSettings; } | undefined) =>
    class AuthProvider extends React.Component<AuthenticatedComponentProps, AuthenticatedComponentState> {
        private useRedirectForAuthentication = true;
        private msalInstance: any;

        constructor(props: AuthenticatedComponentProps) {
            super(props);

            this.state = {
                account: null,
                token: null,
                error: null,
                isUserAuthenticated: false,
                isAuthenticating: true,
            };

            console.log("AuthenticatedComponentProps: ", props);

        }

        async componentDidMount() {
            let skipSignIn = false;
            let msalConfigFromServer: IAuthenticationSettings;

            console.log("componentDidMount: ");

            if (!componentProps || !componentProps.applicationSettings || !componentProps.applicationSettings.authenticationSettings) {
                let applicationSettings: IApplicationSettings = await this.props.dataProvider.getApplicationSettings();
                componentProps = { applicationSettings: applicationSettings }
            }

            msalConfigFromServer = componentProps.applicationSettings.authenticationSettings;

            let msalConfigUpdated = {
                ...msalConfig
            };

            msalConfigUpdated.auth = {
                clientId: msalConfigFromServer.clientId,
                authority: msalConfigFromServer.authority,
                navigateToLoginRequestUrl: msalConfig.auth.navigateToLoginRequestUrl
            };

            console.log("msalConfig: ", msalConfigUpdated);

            this.msalInstance = new msal.PublicClientApplication(msalConfigUpdated);
            const component = this;

            Providers.globalProvider = new SimpleProvider(async function (scopes) {
                var request = { scopes: scopes };
                let currentAccounts = component.msalInstance.getAllAccounts();
                const loginRequestNew = { ...loginRequest, account: currentAccounts[0] };

                try {
                    let response = await component.msalInstance.acquireTokenSilent(loginRequestNew);
                    return response.accessToken;
                } catch (error) {
                    if (component.requiresInteraction(error.errorCode)) {
                        component.msalInstance.acquireTokenRedirect(request);
                    }
                }
            });

            await this.msalInstance.handleRedirectPromise().then(this.handleResponse).catch((err: any) => {
                console.error(err);
                if (err.errorCode === 'access_denied') {
                    skipSignIn = true;
                    Providers.globalProvider.setState(ProviderState.SignedOut);
                    this.setState({
                        account: null,
                        token: null,
                        error: null,
                        isUserAuthenticated: false,
                        isAuthenticating: false,
                    })
                }
            });

            if (!skipSignIn) {
                let currentAccounts: any[];

                currentAccounts = this.msalInstance.getAllAccounts();

                if (currentAccounts === null || currentAccounts.length === 0) {
                    this.onSignIn(this.useRedirectForAuthentication);
                } else if (currentAccounts.length > 1) {
                    // Add choose account code here
                    console.warn("Multiple accounts detected.");
                } else if (currentAccounts.length === 1) {
                    const loginRequestNew = { ...loginRequest, account: currentAccounts[0] };

                    const tokenResponse = await this.acquireToken(loginRequestNew, this.useRedirectForAuthentication)
                        .catch(error => {
                            this.setState({
                                error: error.message
                            });
                        });

                    if (tokenResponse) {
                        const user = await fetchMsGraph(graphConfig.graphMeEndpoint,tokenResponse.accessToken);
                        this.setState({
                            account: user,
                            token: currentAccounts[0],
                            error: null,
                            isUserAuthenticated: true,
                            isAuthenticating: false,
                        })

                    }
                }
            }
        }

        private requiresInteraction = (errorCode: string) => {
            if (!errorCode || !errorCode.length) {
                return false;
            }
            return (
                errorCode === 'consent_required' || errorCode === 'interaction_required' || errorCode === 'login_required'
            );
        }

        private handleResponse = async (resp: any) => {

            if (resp !== null) {
                const loginRequestNew = { ...loginRequest, account: resp.account };
                const tokenResponse = await this.acquireToken(loginRequestNew, this.useRedirectForAuthentication)
                    .catch(error => {
                        this.setState({
                            error: error.message
                        });
                    });

                if (tokenResponse) {
                    const user = await fetchMsGraph(graphConfig.graphMeEndpoint,tokenResponse.accessToken);
                    this.setState({
                        account: user,
                        token: resp.account,
                        error: null,
                        isUserAuthenticated: true,
                        isAuthenticating: false,
                    })

                }
            } else {
                // need to call getAccount here?
                const currentAccounts = this.msalInstance.getAllAccounts();
                if (!currentAccounts || currentAccounts.length < 1) {
                    return;
                } else if (currentAccounts.length > 1) {
                    // Add choose account code here
                } else if (currentAccounts.length === 1) {
                    const loginRequestNew = { ...loginRequest, account: currentAccounts[0] };

                    const tokenResponse = await this.acquireToken(loginRequestNew, this.useRedirectForAuthentication)
                        .catch(error => {
                            this.setState({
                                error: error.message
                            });
                        });

                    if (tokenResponse) {
                        const user = await fetchMsGraph(graphConfig.graphMeEndpoint,tokenResponse.accessToken);
                        this.setState({
                            account: user,
                            token: currentAccounts[0],
                            error: null,
                            isUserAuthenticated: true,
                            isAuthenticating: false,
                        });

                    }
                }
            }

            Providers.globalProvider.setState(ProviderState.SignedIn);
        }


        acquireToken = async (request: any, redirect: boolean) => {
            return this.msalInstance.acquireTokenSilent(request).catch((error: any) => {
                console.warn("silent token acquisition fails. acquiring token using redirect");
                if (error instanceof InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    if (redirect)
                        return this.msalInstance.acquireTokenRedirect(request)
                            .then((tokenResponse: any) => {
                                console.log(tokenResponse);
                                return tokenResponse;
                            }).catch((error: any) => {
                                console.error(error);
                            });
                    else
                        return this.msalInstance.acquireTokenPopup(request)
                            .then((tokenResponse: any) => {
                                console.log(tokenResponse);
                                return tokenResponse;
                            }).catch((error: any) => {
                                console.error(error);
                            });

                } else {
                    console.warn(error);
                }
            });
        }

        onSignIn = async (redirect: boolean) => {
            if (redirect) {
                return this.msalInstance.loginRedirect({
                    ...loginRequest
                });
            }

            const loginResponse = await this.msalInstance
                .loginPopup(loginRequest)
                .catch((error: any) => {
                    this.setState({
                        error: error.message
                    });
                });


            if (loginResponse) {
                const loginRequestNew = { ...loginRequest, account: loginResponse.account };

                const tokenResponse = await this.acquireToken(loginRequestNew, this.useRedirectForAuthentication).catch(error => {
                    this.setState({
                        error: error.message
                    });
                });

                if (tokenResponse) {
                    const user = await fetchMsGraph(graphConfig.graphMeEndpoint,tokenResponse.accessToken);
                    this.setState({
                        account: user,
                        token: loginResponse.account,
                        error: null,
                        isUserAuthenticated: true,
                        isAuthenticating: false,
                    })
                }
            }
        }

        onSignOut() {
            this.msalInstance.logout();
            Providers.globalProvider.setState(ProviderState.SignedOut);
        }

        render() {
            const { error, account, isUserAuthenticated, isAuthenticating, token } = this.state;

            if (isAuthenticating) {
                return (
                    <Stack className="loggingInContainer" horizontalAlign="center" verticalAlign="center" reversed={true} >
                        <span>Trying to Log-In</span>
                        <div className="loggingInLoader">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </Stack>);
            }
            else {
                if (isUserAuthenticated) {
                    return (
                        <AuthenticatedComponent
                            {...this.props}
                            {...componentProps}
                            userAccount={account}
                            token= {token}
                            isUserAuthenticated={isUserAuthenticated}
                        />
                    );
                }
                else {
                    return (
                        <Stack className="fullWidth" verticalAlign="space-between" horizontalAlign="center">
                            {
                                error ?
                                    <MessageBar
                                        onDismiss={() => {
                                            this.setState({
                                                error: null
                                            });
                                        }}
                                        messageBarType={MessageBarType.error}
                                        isMultiline={true}
                                        truncated={true}
                                    >
                                        {error}
                                    </MessageBar>
                                    : null
                            }
                            <h3 className="signInLabel"><LockIcon />Please sign-in into your account to access this page.</h3>
                            <PrimaryButton
                                text="Sign In"
                                onClick={() => {
                                    this.onSignIn(true);
                                }}
                                allowDisabledFocus
                            />
                        </Stack>
                    );
                }
            }

        }
    };
