import * as React from 'react';
import { css, Stack } from '@fluentui/react';
import { PageURL } from '../../App';
import styles from './Welcome.module.scss';
import { IApplicationSettings } from '../../Models';
import { IDataProvider } from '../../dataprovider/IDataProvider';
import { Link } from 'react-router-dom';


export interface IWelcomePageProps {
    location?: Location;
    history?: any;
    dataProvider: IDataProvider;
    applicationSettings?: IApplicationSettings;
}

export interface IWelcomePageState {
    applicationSettings?: IApplicationSettings;
}

export default class WelcomePage extends React.Component<IWelcomePageProps, IWelcomePageState> {

    constructor(props: IWelcomePageProps) {
        super(props);
        this.state = {
            applicationSettings: props.applicationSettings
        };
    }

    componentDidUpdate(prevProps: IWelcomePageProps, prevState: IWelcomePageState) {
        if (!prevProps.applicationSettings && this.props.applicationSettings) {
            this.setState({
                applicationSettings: this.props.applicationSettings
            });
        }
    }

    public render() {

        if (!this.state.applicationSettings)
            return (
                <Stack className={styles.loggingInContainer} horizontalAlign="center" verticalAlign="center" reversed={true} >
                    <span>Loading</span>
                    <div className={styles.loggingInLoader}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </Stack>
            );
        else {

            const { applicationLabels, authenticationSettings, themes, cards } = this.state.applicationSettings;

            return (
                <div className={styles.root}>
                    <div className={css(styles.container, styles.page)}>
                        <div className={styles.header}>
                            <Link to="/">
                                <div className={styles.logo}><span>ARISE</span></div>
                            </Link>
                        </div>
                        <div className={css(styles.contentContainer, styles.home)}>
                            <h1 className="richTextLabelResponsive">{applicationLabels.login_heading_1}</h1>
                            <h2 className={css(styles.mAuto, styles.pt5, styles.floatRight, styles.colLg4, styles.tagLine, "richTextLabelResponsive")}>{applicationLabels.login_heading_2}</h2>
                        </div>
                        <div className={styles.messageBox}>
                            <div className={styles.message}>
                                <h2 className={css("text-primary", "richTextLabelResponsive")}>{applicationLabels.login_subHeading_Greetings}</h2>
                                <h4 className={css(styles.pb2, "richTextLabelResponsive")}>{applicationLabels.login_subHeading_LogIn}</h4>
                                <div className={"richTextLabelResponsive"}>{applicationLabels.login_subHeading_LoggedInAlready}</div>
                                <div className={styles.pt2}>
                                    <Link to={PageURL.SendHiVibe} >
                                        <button className={css(styles.btn, styles.btnPrimary, styles.mAuto, styles.p3, styles.submitCard)}>{applicationLabels.login_button_GoToHiVibe}</button>
                                    </Link>
                                </div>
                                <div className={css(styles.disclaimer, styles.pt4, "richTextLabelResponsive")}>{applicationLabels.login_disclaimer}</div>
                                <div className={styles.messageFooter}>
                                    <hr className={styles.divider} />
                                    <h4 className={css("text-primary", styles.pt3, styles.pb2, "richTextLabelResponsive")}>{applicationLabels.login_subHeading_ColleagueSkill}</h4>
                                    <h6 className={css("text-primary", "richTextLabelResponsive")}>{applicationLabels.login_subHeading_SendHiVibe}</h6>
                                </div>
                            </div>
                        </div>
                        <div className={styles.footer}>
                            <div className={styles.appLogo}></div>
                        </div>
                    </div>
                </div>
            );
        }

    }

}