import * as React from 'react';
// import './Layout.scss';
// import logo from '../../images/str-group-white.svg';
// import { Link } from 'react-router-dom';

// import { Stack } from '@fluentui/react';

export class Layout extends React.Component<any, any> {
    static displayName = Layout.name;
    // private year = new Date().getFullYear();

    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <>{this.props.children}</>
        );
    }
}
