import * as React from 'react';
import { MessageBarType, Stack, css, Callout, FontIcon, DirectionalHint, TextField, Spinner, SpinnerSize, ITextFieldStyleProps, MessageBar, ShimmerElementType, ShimmerElementsGroup, Shimmer, FocusTrapCallout, FocusZone, ICalloutProps, Modal } from '@fluentui/react';
import { IHiVibe, IApplicationSettings, IPerson, ErrorState, IApplicationLabels } from '../../Models';
import { Common } from '../../dataprovider/Common';
import { Link } from 'react-router-dom';

import { IDataProvider } from '../../dataprovider/IDataProvider';
import { GroupType, IDynamicPerson, PeoplePicker, PersonType } from '@microsoft/mgt-react/dist/es6/generated/react';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';

import { Picker } from 'emoji-mart';

import commonStyles from '../WelcomePage/Welcome.module.scss';
import styles from './HiVibeNewForm.module.scss';

import 'emoji-mart/css/emoji-mart.css'

const readOnlyTextBoxStyles = {
    wrapper: {
        flexDirection: 'column',
        borderBottom: '1px solid rgb(214 211 208)',

        '& label': {
            padding: 0,
            color: "#4c79bc",
            fontWeight: 400,
            fontSize: '0.8rem',
            lineHeight: '15px',
            height: 'auto',
            fontFamily: '"TheSans-LP3Light", sans-serif'
        }
    },
    fieldGroup: {
        height: '30px',
        flex: '1 0 auto !important',
        // display: 'block',

        '& input': {
            background: '#fff',
            paddingLeft: 0,
            width: '100%',
            color: '#a19f9d', //'#595858',
            '-webkit-text-fill-color': '#a19f9d',
            opacity: 1 /* required on iOS */
        }
    }
};

export interface IHiVibeNewFormProps {
    isUserAuthenticated: boolean;
    userAccount: MicrosoftGraph.User;
    dataProvider: IDataProvider;
    location: Location;
    history: History;
    applicationSettings: IApplicationSettings;
}

export interface IHiVibeNewFormState {
    showWaitingSpinner?: boolean;
    hiVibe: IHiVibe;
    applicationSettings: IApplicationSettings;
    message?: string | JSX.Element | null;
    messageType?: MessageBarType;
    showMessage?: boolean;
    isLoading?: boolean;
    isThemeCalloutVisible?: boolean;
    isCardCalloutVisible?: boolean;
    isEmojiPickerVisible?: boolean;
    otherTheme?: string;
    otherThemeError?: string;
    errorState: ErrorState;
    selectedPeople?: IDynamicPerson[];
    imageLoaded?: boolean[];
}

export default class HiVibeNewForm extends React.Component<IHiVibeNewFormProps, IHiVibeNewFormState> {
    static displayName = HiVibeNewForm.name;

    private themeCallOutProps: ICalloutProps = {
        role: "alertdialog",
        gapSpace: 0,
        target: `.${styles.chooseTheme} .${styles.fontIconText}`,
        directionalHint: DirectionalHint.rightCenter,
        onDismiss: () => {
            this.setState({
                isThemeCalloutVisible: false
            });
        },
        setInitialFocus: true,
        // preventDismissOnEvent: (event:any)=>{
        //     console.log(event);
        //     return true;
        // },
        preventDismissOnResize: true,
        preventDismissOnScroll: true,
        preventDismissOnLostFocus: true
    }

    constructor(props: IHiVibeNewFormProps) {
        super(props);
        this.state = {
            hiVibe: {
                sender: {
                    Title: props.userAccount.displayName,
                    EMail: props.userAccount.mail,
                    Name: props.userAccount.userPrincipalName
                },
                jobTitle: props.userAccount.jobTitle,
                country: props.userAccount.country,
                officeLocation: props.userAccount.officeLocation,
                department: props.userAccount.department,
                locale: navigator.language
            },
            showWaitingSpinner: false,
            applicationSettings: props.applicationSettings,
            message: null,
            messageType: MessageBarType.success,
            showMessage: false,
            isLoading: false,
            isThemeCalloutVisible: false,
            isCardCalloutVisible: false,
            isEmojiPickerVisible: false,
            errorState: {
                theme: {
                    errorMessage: '',
                    showError: false
                },
                card: {
                    errorMessage: '',
                    showError: false
                },
                message: {
                    errorMessage: '',
                    showError: false
                },
                receivers: {
                    errorMessage: '',
                    showError: false
                },
            },
            selectedPeople: [],
            imageLoaded: props.applicationSettings.cards.map((x: string) => { return false; }),
        };
    }

    async componentDidMount() {
        try {

            if (!this.props.applicationSettings) {
                const applicationSettings = await this.props.dataProvider.getApplicationSettings();
                this.setState({
                    applicationSettings: applicationSettings,
                    imageLoaded: applicationSettings.cards.map(x => { return false; })
                });
            }

            (document.getElementById(`root`) as HTMLDivElement).addEventListener('click', this.onClickPage);

        } catch (error) {
            this.hideSpinnerAndShowErrorMessage(error, "componentDidMount");
        }

    }

    componentWillUnmount() {
        (document.getElementById(`root`) as HTMLDivElement).removeEventListener('click', this.onClickPage);
    }

    private onClickPage = (e: Event) => {
        // console.log(e);
        const composedPath = e.composedPath();
        console.log("ComposedPath: ", composedPath);

        const indexOfEmojiPicker = composedPath.findIndex(x => (x as any).classList && (x as any).classList.contains("emoji-mart"));
        const indexOfEmojiPickerIcon = composedPath.findIndex(x => (x as any).classList && (x as any).classList.contains(styles.emojiIcon));
        if (indexOfEmojiPicker === -1 && indexOfEmojiPickerIcon === -1) {
            this.setState({
                isEmojiPickerVisible: false
            });
        }
    }

    private hideSpinnerAndShowSuccessMessage = (message: string) => {
        this.setState({
            message: message,
            messageType: MessageBarType.success,
            showWaitingSpinner: false,
            showMessage: true,
        });

        window.setTimeout(this.resetMessageBar, 10000);
    }

    private resetMessageBar = () => {
        this.setState({
            showMessage: false,
            messageType: MessageBarType.success,
            message: undefined
        });
    }

    private hideSpinnerAndShowErrorMessage = (error: any, errorAreaName: string) => {

        this.props.dataProvider.logErrorInConsole?.(errorAreaName, error);

        this.setState({
            message: this.props.dataProvider.extractErrorMessageFromErrorObject?.(error),
            messageType: MessageBarType.error,
            showWaitingSpinner: false,
            showMessage: true,
        });
    }

    private onChangePeoplePicker = (e: Event) => {
        const hiVibe: IHiVibe = { ...this.state.hiVibe };
        const errorState: ErrorState = { ...this.state.errorState };
        const applicationLabels = this.state.applicationSettings.applicationLabels;

        const spUsers: IPerson[] = [];

        // console.log(e);
        // console.log((e.target as any).selectedPeople);
        let users: IDynamicPerson[] = (e.target as any).selectedPeople;

        for (let index = 0; index < users.length; index++) {
            const user = users[index];
            let email;
            let person = user as MicrosoftGraph.Person;

            if (person.scoredEmailAddresses && person.scoredEmailAddresses.length) {
                email = person.scoredEmailAddresses[0].address ?? "";
            }

            if (!email) {
                email = (user as MicrosoftGraph.User).userPrincipalName ?? "";
            }
            else {
                if (!(user as MicrosoftGraph.User).userPrincipalName) {
                    errorState.receivers = {
                        errorMessage: applicationLabels.error_message_ExternalUser,
                        showError: true
                    };

                    this.setState({
                        errorState: errorState,
                    });
                    return;
                }
            }

            // let email: string = (user as MicrosoftGraph.User).userPrincipalName ?? "";
            // let displayName: string = (user as MicrosoftGraph.User).displayName ?? "";

            if (!email) {
                email = (user as MicrosoftGraph.Group).mail ?? "";
            }

            spUsers.push({
                EMail: email,
                Title: user.displayName,
                Id: null,
                Name: person.userPrincipalName ?? null
            });
        }

        hiVibe.receivers = spUsers;

        errorState.receivers = {
            errorMessage: "",
            showError: false
        }

        this.setState({
            hiVibe: hiVibe,
            errorState: errorState,
            selectedPeople: users
        });
    }

    private onChangeTextField = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
        const hiVibe: IHiVibe = { ...this.state.hiVibe };
        const errorState: ErrorState = { ...this.state.errorState };

        hiVibe.message = newValue;

        errorState.message = {
            errorMessage: "",
            showError: false
        }

        this.setState({
            hiVibe: hiVibe,
            errorState: errorState
        });
    }

    private addEmoji = (e: any) => {
        let emoji = e.native;
        const hiVibe: IHiVibe = { ...this.state.hiVibe };
        hiVibe.message = (hiVibe.message ?? "") + emoji;

        console.log("Emoji: ", emoji);

        this.setState({
            hiVibe: hiVibe,
        });
    };

    private openThemeCallout = () => {
        this.setState({
            otherTheme: undefined,
            isThemeCalloutVisible: true
        });
    }

    private openCardCallout = () => {
        this.setState({
            isCardCalloutVisible: true
        });
    }

    private selectTheme = (themeKey: string, themeTitle: string) => () => {
        const hiVibe: IHiVibe = { ...this.state.hiVibe };
        const errorState: ErrorState = { ...this.state.errorState };

        hiVibe.theme = themeTitle;
        hiVibe.themeKey = themeKey;

        errorState.theme = {
            errorMessage: "",
            showError: false
        }

        this.setState({
            hiVibe: hiVibe,
            errorState: errorState,
            isThemeCalloutVisible: false
        });
    }

    private selectCard = (card: string) => () => {
        const hiVibe: IHiVibe = { ...this.state.hiVibe };
        const errorState: ErrorState = { ...this.state.errorState };

        hiVibe.card = card;

        errorState.card = {
            errorMessage: "",
            showError: false
        }

        this.setState({
            hiVibe: hiVibe,
            errorState: errorState,
            isCardCalloutVisible: false
        });
    }

    private onClickUseThemButton = () => {
        if (this.state.otherTheme) {
            const hiVibe: IHiVibe = { ...this.state.hiVibe };
            hiVibe.theme = this.state.otherTheme;

            this.setState({
                isThemeCalloutVisible: false,
                hiVibe: hiVibe
            });
        }
        else {
            const { applicationLabels } = this.state.applicationSettings;
            this.setState({
                otherThemeError: applicationLabels.error_message_Required
            });
        }
    }

    private onClickSendHiVibeButton = () => {
        const { applicationLabels } = this.state.applicationSettings;
        const hiVibe: IHiVibe = this.state.hiVibe;
        const errorState: ErrorState = { ...this.state.errorState };
        let isFormValid = true;

        if (!hiVibe.theme) {
            errorState.theme = {
                errorMessage: applicationLabels.error_message_Required,
                showError: true
            }

            isFormValid = false
        }
        else {
            errorState.theme = {
                errorMessage: "",
                showError: false
            }
        }

        if (!hiVibe.card) {
            errorState.card = {
                errorMessage: applicationLabels.error_message_Required,
                showError: true
            }

            isFormValid = false
        }
        else {
            errorState.card = {
                errorMessage: "",
                showError: false
            }
        }

        if (!hiVibe.message) {
            errorState.message = {
                errorMessage: applicationLabels.error_message_Required,
                showError: true
            }

            isFormValid = false
        }
        else {
            errorState.message = {
                errorMessage: "",
                showError: false
            }
        }

        if (!hiVibe.receivers || hiVibe.receivers.length === 0) {
            errorState.receivers = {
                errorMessage: applicationLabels.error_message_Required,
                showError: true
            }

            isFormValid = false
        }
        else {
            errorState.receivers = {
                errorMessage: "",
                showError: false
            }
        }

        if (isFormValid) {
            this.setState({
                showWaitingSpinner: true
            });

            this.props.dataProvider.createHiVibe(hiVibe).then((result) => {
                const hiVibe: IHiVibe = this.state.hiVibe;

                hiVibe.isRead = false;
                hiVibe.message = null;
                hiVibe.theme = undefined;
                hiVibe.card = undefined;
                hiVibe.receivers = undefined;

                (document.querySelector('mgt-people-picker') as any).selectUsersById([])

                this.setState({
                    hiVibe: hiVibe,
                    selectedPeople: []
                });

                this.hideSpinnerAndShowSuccessMessage(applicationLabels.success_message_HiVibeCreated);
            }).catch(error => {
                this.hideSpinnerAndShowErrorMessage(error, 'onClickSendHiVibeButton')
            });
        }
        else {
            this.setState({
                errorState: errorState
            });
        }
    }

    renderThemeCalloutContent = (applicationLabels: IApplicationLabels, themes: any, otherTheme: string, otherThemeError: string) => {
        return (
            <div className={styles.themeContainerCallout}>
                <h2>{applicationLabels.form_label_ThanksFor}:</h2>
                <div className={styles.themeList}>
                    {
                        Object.entries(themes).map((theme, i) => {
                            return (
                                <div className={styles.themeTextContainer} key={`theme${i}`}>
                                    <h3 className={styles.themeText} onClick={this.selectTheme(theme[0], theme[1] as string)}><FontIcon className={styles.fontIconTextTheme} iconName="ChevronRightSmall"></FontIcon> {theme[1]}</h3>
                                </div>
                            );
                        })
                    }
                </div>
                {/*<Stack horizontal horizontalAlign="space-between" verticalAlign="start">*/}
                {/*    <TextField*/}
                {/*        value={otherTheme}*/}
                {/*        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {*/}
                {/*            this.setState({*/}
                {/*                otherTheme: newValue,*/}
                {/*                otherThemeError: newValue ? '' : applicationLabels.error_message_Required*/}
                {/*            });*/}
                {/*        }}*/}
                {/*        maxLength={30}*/}
                {/*        styles={{*/}
                {/*            field: {*/}
                {/*                lineHeight: '10px'*/}
                {/*            }*/}
                {/*        }}*/}
                {/*        borderless*/}
                {/*        underlined*/}
                {/*        placeholder={applicationLabels.form_placeholder_UseButton}*/}
                {/*        errorMessage={otherThemeError}*/}
                {/*    />*/}
                {/*    <button onClick={this.onClickUseThemButton} className={css(commonStyles.btn, commonStyles.btnPrimary, styles.useButton)}>{applicationLabels.form_button_Use}</button>*/}
                {/*</Stack>*/}
            </div>
        );
    }

    render() {
        const {
            showMessage, message, isLoading, messageType, isEmojiPickerVisible, otherThemeError, selectedPeople,
            hiVibe, showWaitingSpinner, isThemeCalloutVisible, isCardCalloutVisible, otherTheme, errorState
        } = this.state;

        const { themes, applicationLabels, cards } = this.state.applicationSettings;
        const { location, history, isUserAuthenticated, userAccount } = this.props;

        // console.log(stepWizardContext);

        if (window.outerWidth < 768)
            this.themeCallOutProps = {
                ...this.themeCallOutProps,
                preventDismissOnResize: true,
                preventDismissOnScroll: true,
                preventDismissOnLostFocus: true
            };
        else
            this.themeCallOutProps = {
                ...this.themeCallOutProps,
                preventDismissOnResize: false,
                preventDismissOnScroll: false,
                preventDismissOnLostFocus: false
            };

        if (isLoading) {
            return (
                <Stack className="loggingInContainer" horizontalAlign="center" verticalAlign="center" reversed={true} >
                    <span>Loading</span>
                    <div className="loggingInLoader">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </Stack>
            );
        }
        else {
            let fileNameParts = hiVibe?.card ? hiVibe.card.split('.') : [];
            let thumbnailNameCard = fileNameParts[0];

            if (fileNameParts.length > 1)
                thumbnailNameCard = `${thumbnailNameCard}_thumbnail.${fileNameParts[1]}`;

            if (this.state && this.state.applicationSettings)
                return (
                    <div className={commonStyles.root}>
                        <div className={css(commonStyles.container, commonStyles.page)}>
                            <div className={commonStyles.header}>
                                <Link to="/">
                                    <div className={commonStyles.logo}><span>ARISE</span></div>
                                </Link>
                            </div>
                            <div className={css(commonStyles.contentContainer, commonStyles.home)}>
                                <h1 className="richTextLabelResponsive">{applicationLabels.login_heading_1}</h1>
                                <h2 className={css(commonStyles.mAuto, commonStyles.pt5, commonStyles.floatRight, commonStyles.colLg4, commonStyles.tagLine, "richTextLabelResponsive")}>{applicationLabels.login_heading_2}</h2>
                            </div>
                            <div className={commonStyles.messageBox}>
                                <div className={commonStyles.message}>
                                    {
                                        showMessage &&
                                        <MessageBar
                                            className="message-bar"
                                            messageBarType={messageType}
                                            isMultiline={false}
                                            onDismiss={() => {
                                                this.resetMessageBar();
                                            }}
                                        >{message}
                                        </MessageBar>
                                    }
                                    <Stack horizontal horizontalAlign="start" verticalAlign="center" className={styles.chooseSection}>
                                        <h2 className={css("richTextLabelResponsive", styles.chooseTheme, { "red-text": errorState.theme && errorState.theme.showError ? true : false })} onClick={this.openThemeCallout}>{applicationLabels.form_label_ChooseTheme} <FontIcon className={styles.fontIconText} iconName="ChevronRightSmall"></FontIcon></h2>
                                        {
                                            hiVibe?.theme &&
                                            <Stack className={styles.themeSelected}>
                                                <div>{applicationLabels.form_label_ThanksFor}</div>
                                                <div>{hiVibe?.theme ?? ""}</div>
                                            </Stack>
                                        }
                                    </Stack>
                                    <Stack horizontal horizontalAlign="start" verticalAlign="center" className={styles.chooseSection}>
                                        <h2 className={css("richTextLabelResponsive", styles.chooseCard, { "red-text": errorState.card && errorState.card.showError ? true : false })} onClick={this.openCardCallout}>{applicationLabels.form_label_ChooseCard} <FontIcon className={styles.fontIconText} iconName="ChevronRightSmall"></FontIcon></h2>
                                        {
                                            hiVibe?.card &&
                                            <Stack className={styles.cardSelected}>
                                                <div><img src={`/Cards/${thumbnailNameCard}`} className={styles.cardThumbnail} /></div>
                                            </Stack>
                                        }
                                    </Stack>
                                    <div className={styles.row}>
                                        <TextField
                                            label={applicationLabels.form_label_YourName}
                                            styles={readOnlyTextBoxStyles}
                                            underlined
                                            disabled
                                            defaultValue={hiVibe?.sender?.Title ?? ""} />
                                    </div>
                                    <div className={styles.row}>
                                        <TextField
                                            label={applicationLabels.form_label_YourEMail}
                                            styles={readOnlyTextBoxStyles}
                                            underlined
                                            disabled
                                            defaultValue={hiVibe?.sender?.EMail ?? ""} />
                                    </div>
                                    <div className={css(styles.row, styles.flexColumn)}>
                                        <label className={css(styles.labelForm, { "red-text": errorState.card && errorState.card.showError ? true : false })}>{applicationLabels.form_label_Recipient}</label>
                                        <PeoplePicker
                                            className={css("custom-class", { error: errorState.card && errorState.card.showError ? true : false })}
                                            type={PersonType.any}
                                            groupType={GroupType.mailenabledsecurity}
                                            selectionMode="multiple"
                                            placeholder={applicationLabels.form_label_Recipient}
                                            showMax={6}
                                            selectionChanged={this.onChangePeoplePicker}
                                            selectedPeople={selectedPeople}
                                        />
                                        {errorState.receivers && errorState.receivers.showError && <span className="error-text">{errorState.receivers.errorMessage}</span>}
                                    </div>
                                    <div className={styles.row}>
                                        <TextField
                                            multiline
                                            styles={(props: ITextFieldStyleProps) => {

                                                return {
                                                    wrapper: {
                                                        '& label': {
                                                            padding: 0,
                                                            color: props.hasErrorMessage ? "rgb(164, 38, 44)" : "#4c79bc",
                                                            fontWeight: 400,
                                                            fontSize: '0.8rem',
                                                            // lineHeight: '15px',
                                                            height: 'auto',
                                                            marginBottom: '5px',
                                                            fontFamily: '"TheSans-LP3Light", sans-serif'
                                                        }
                                                    },
                                                    errorMessage: {
                                                        fontSize: '0.7rem'
                                                    }
                                                };
                                            }}
                                            rows={5}
                                            placeholder={applicationLabels.form_label_YourMessage}
                                            label={applicationLabels.form_label_YourMessage}
                                            value={hiVibe?.message ?? ""}
                                            onChange={this.onChangeTextField}
                                            errorMessage={errorState.message.errorMessage}
                                        />
                                        <div className={styles.emojiContainer}>
                                            <FontIcon
                                                iconName="Emoji2"
                                                className={styles.emojiIcon}
                                                onClick={() => {
                                                    this.setState({
                                                        isEmojiPickerVisible: !this.state.isEmojiPickerVisible
                                                    });
                                                }}
                                            />
                                            {
                                                isEmojiPickerVisible &&
                                                <Stack className={styles.emojiPicker}>
                                                    <div className={styles.closeBar}>
                                                        <FontIcon
                                                            iconName="ChromeClose"
                                                            className={styles.closeIcon}
                                                            onClick={() => {
                                                                this.setState({
                                                                    isEmojiPickerVisible: false
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                    <Picker
                                                        title={applicationLabels.form_label_SelectEmoji}
                                                        onSelect={this.addEmoji}
                                                    // style={{
                                                    //     position: 'absolute',
                                                    //     bottom: 0,
                                                    //     left: '20px'
                                                    // }}
                                                    />
                                                </Stack>

                                            }

                                        </div>
                                    </div>
                                    <Stack horizontalAlign="center" className="">
                                        {
                                            showWaitingSpinner ?
                                                <Spinner size={SpinnerSize.large} label="Working on it..." ariaLive="assertive" />
                                                :
                                                <button
                                                    onClick={this.onClickSendHiVibeButton}
                                                    className={css(commonStyles.btn, commonStyles.btnPrimary, commonStyles.mAuto, styles.submitCard)}
                                                >{applicationLabels.form_Button_SendYourHiVibe}</button>
                                        }

                                    </Stack>

                                </div>
                            </div>
                            <div className={commonStyles.footer}>
                                <div className={commonStyles.appLogo}></div>
                            </div>
                            {
                                isThemeCalloutVisible && window.outerWidth<768 ?
                                <Modal
                                    isBlocking= {true}
                                    isOpen={isThemeCalloutVisible}
                                >
                                    {this.renderThemeCalloutContent(applicationLabels, themes, otherTheme ?? "", otherThemeError ?? "")}
                                </Modal>
                                :
                                isThemeCalloutVisible && window.outerWidth>=768 ?
                                <FocusTrapCallout {...this.themeCallOutProps}>
                                    <FocusZone>
                                        {this.renderThemeCalloutContent(applicationLabels, themes, otherTheme ?? "", otherThemeError ?? "")}
                                    </FocusZone>
                                </FocusTrapCallout>
                                : null

                            }
                            {
                                isCardCalloutVisible &&
                                <Callout
                                    role="alertdialog"
                                    gapSpace={0}
                                    target={`.${styles.chooseCard} .${styles.fontIconText}`}
                                    directionalHint={DirectionalHint.rightCenter}
                                    onDismiss={() => {
                                        this.setState({
                                            isCardCalloutVisible: false
                                        });
                                    }}
                                    setInitialFocus
                                >
                                    <div className={styles.cardContainer}>
                                        {
                                            cards.map((card, i) => {
                                                let fileNameParts = card.split('.');
                                                let thumbnailName = fileNameParts[0];
                                                thumbnailName = `${thumbnailName}_thumbnail.${fileNameParts[1]}`;

                                                const shimmerElementGroup = <ShimmerElementsGroup
                                                    shimmerElements={[
                                                        { type: ShimmerElementType.line, width: '100%' }
                                                    ]}
                                                />

                                                const isImageLoaded = this.state.imageLoaded ? this.state.imageLoaded[i] : false
                                                //const isImageLoaded = false

                                                return (
                                                    <div className={styles.card} key={`theme${i}`}>
                                                        <Shimmer
                                                            style={{ display: "flex", width: '100%', height: '100%' }}
                                                            isDataLoaded={isImageLoaded}
                                                            customElementsGroup={shimmerElementGroup}
                                                        >
                                                        </Shimmer>
                                                        <img
                                                            style={{ display: isImageLoaded ? "block" : "none" }}
                                                            onLoad={() => {
                                                                const imageLoaded = this.state.imageLoaded ? [...this.state.imageLoaded] : [];
                                                                if (imageLoaded.length) {
                                                                    console.log("Loaded Image: ", thumbnailName);
                                                                    imageLoaded[i] = true;
                                                                    this.setState({ imageLoaded: imageLoaded });
                                                                }
                                                            }}
                                                            // className={styles.themeText}
                                                            placeholder={thumbnailName}
                                                            alt={thumbnailName}
                                                            onClick={this.selectCard(card)}
                                                            src={`/Cards/${thumbnailName}`}
                                                        />
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                    {

                                    }
                                </Callout>
                            }
                        </div>
                    </div>
                );
            else
                return null;

        }

    }
}