import * as React from 'react';
import { MessageBarType, Stack, css } from '@fluentui/react';
import { IHiVibe, IApplicationSettings } from '../../Models';
import { Link, match } from 'react-router-dom';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { IDataProvider } from '../../dataprovider/IDataProvider';
import commonStyles from '../WelcomePage/Welcome.module.scss';
import styles from './HiVibeDisplayForm.module.scss';
import straumannLogo from '../../images/str-group-color.svg';
import { PageURL } from '../../App';

export interface IHiVibeDisplayFormProps {
    isUserAuthenticated: boolean;
    userAccount: MicrosoftGraph.User;
    dataProvider: IDataProvider;
    location: Location;
    history: History;
    applicationSettings: IApplicationSettings;
    match: any;
}

export interface IHiVibeDisplayFormState {
    requestId: string;
    showWaitingSpinner?: boolean;
    hiVibe?: IHiVibe;
    applicationSettings: IApplicationSettings;
    message?: string | JSX.Element | null;
    messageType?: MessageBarType;
    showOrderSuccessMessage?: boolean;
    isLoading?: boolean;
}

export default class HiVibeDisplayForm extends React.Component<IHiVibeDisplayFormProps, IHiVibeDisplayFormState> {
    static displayName = HiVibeDisplayForm.name;

    constructor(props: IHiVibeDisplayFormProps) {
        super(props);

        let query = new URLSearchParams((this.props as any).location.search);
        const id: string = query.get("id") ?? '';

        this.state = {
            requestId: id,
            showWaitingSpinner: false,
            applicationSettings: props.applicationSettings,
            message: null,
            messageType: MessageBarType.success,
            showOrderSuccessMessage: false,
            isLoading: false,
        };
    }

    async componentDidMount() {
        try {
            let promiseArray: Promise<any>[] = [];

            if (this.state.requestId) {
                promiseArray.push(this.props.dataProvider.getHiVibe(this.state.requestId, this.props.userAccount.mail ?? "", this.props.userAccount.displayName ?? ""));

                if (!this.props.applicationSettings)
                    promiseArray.push(this.props.dataProvider.getApplicationSettings());

                Promise.all(promiseArray).then((result: any[]) => {
                    const hiVibe = result[0];

                    this.setState({
                        hiVibe: hiVibe
                    });

                    if (result.length > 1) {
                        const applicationSettings = result[1];
                        this.setState({
                            applicationSettings: applicationSettings
                        });
                    }
                });
            }
            else {

            }
        } catch (error) {
            this.hideSpinnerAndShowErrorMessage(error, "componentDidMount");
        }

    }

    private hideSpinnerAndShowErrorMessage = (error: any, errorAreaName: string) => {

        this.props.dataProvider.logErrorInConsole?.(errorAreaName, error);

        this.setState({
            message: this.props.dataProvider.extractErrorMessageFromErrorObject?.(error),
            messageType: MessageBarType.error,
            showWaitingSpinner: false,
            showOrderSuccessMessage: false,
        });
    }


    render() {
        const {
            showOrderSuccessMessage, message, isLoading, messageType,
            hiVibe, showWaitingSpinner
        } = this.state;

        const { themes, applicationLabels, cards } = this.state.applicationSettings;
        const { location, history, isUserAuthenticated, userAccount } = this.props;

        // console.log(stepWizardContext);

        if (isLoading && this.state.requestId) {
            return (
                <Stack className="loggingInContainer" horizontalAlign="center" verticalAlign="center" reversed={true} >
                    <span>Loading</span>
                    <div className="loggingInLoader">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </Stack>
            );
        }
        else {
            if (this.state.requestId) {
                if (this.state && this.state.applicationSettings) {
                    return (
                        <div className={css(styles.container, styles.page)}>
                            <div className={commonStyles.header}>
                                <Link to="/">
                                    <div className={commonStyles.logo}><span>ARISE</span></div>
                                </Link>
                            </div>
                            <div className={styles.cardBox}>
                                <div className={styles.card}>
                                    <div className={styles.cardImage} style={{ backgroundImage: `url(/Cards/${hiVibe?.card})` }}>
                                        {/* <img src={`/Cards/${hiVibe?.card}`}></img> */}
                                        <div className={styles.themeContainer}>
                                            <h1>{applicationLabels.form_label_ThanksFor} {hiVibe?.themeKey ? themes[hiVibe.themeKey] : hiVibe?.theme}</h1>
                                        </div>
                                    </div>
                                    <div className={styles.logo}><img src={straumannLogo} /></div>
                                </div>
                                <div className={styles.message}>{hiVibe?.message}</div>
                            </div>
                            <div className={styles.link}>
                                <Link to={PageURL.SendHiVibe}>{applicationLabels.form_label_SendYourOwnHiVibe}</Link>
                            </div>
                        </div>
                    );
                }
                else
                    return null;
            }
            else {
                return <h1 className="red-text">No Request Id specified</h1>
            }

        }

    }
}