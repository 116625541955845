export default class HttpService {

    public static async get(url: string, body?: any): Promise<any> {
        let response: any;

        if (body)
            response = await fetch(url, {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Accept': 'application/json',
                    'CultureInfo': navigator.languages.join(",")
                },
                body: JSON.stringify(body)
            });
        else
            response = await fetch(url, {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Accept': 'application/json',
                    'CultureInfo': navigator.languages.join(",")
                },
            });

        return HttpService.handleResponse(response);
    }

    public static async post(url: string, body: any): Promise<any> {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'CultureInfo': navigator.languages.join(",")
            },
            body: JSON.stringify(body)
        });

        return HttpService.handleResponse(response);
    }

    private static async handleResponse(response: Response) {

        if (response.ok){
            try {
                return response.json();
            } catch (error) {
                return response.text();
            }
        }
        else {
            let errorString: string = "";
            try {
                errorString = await response.text();
                const error = JSON.parse(errorString);
                throw Error(error);
            } catch (error) {
                throw Error(errorString);
            }
        }
    }
}