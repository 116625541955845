import { IDataProvider } from "./IDataProvider";
import { IApplicationLabels, IHiVibe, IApplicationSettings, IAuthenticationSettings } from "../Models";
import HttpService from "./HttpService";

export default class DataProvider implements IDataProvider {

    public apiEndpointSP = `/api/SPInterface`;
    public apiEndpointPayment = `/api/Payment`;
    public apiEndpointAppSettings = `/api/ApplicationSettings`;

    public async getApplicationSettings(): Promise<IApplicationSettings> {
        const applicationSettings: IApplicationSettings = await HttpService.get(`${this.apiEndpointAppSettings}`);
        return applicationSettings;
    }

    public async getAzureConnectivitySettings(): Promise<IAuthenticationSettings> {
        const authenticationSettings: IAuthenticationSettings = await HttpService.get(`/api/AzureConnectivity`);
        return authenticationSettings;
    }

    public async getApplicationLables(): Promise<IApplicationLabels> {
        const applicationLabels: IApplicationLabels = await HttpService.get(`${this.apiEndpointSP}/GetApplicationLabels`);
        return applicationLabels;
    }

    public async getHiVibe(id: string, currentUserEmail: string, currentUserName: string): Promise<IHiVibe> {
        const data={
            id: id,
            currentUserEmail: currentUserEmail,
            currentUserName: currentUserName
        };

        // const order: IHiVibe = await HttpService.get(`${this.apiEndpointSP}/HiVibe?id=${encodeURIComponent(id)}&email=${encodeURIComponent(currentUserEmail)}`);
        const order: IHiVibe = await HttpService.post(`${this.apiEndpointSP}/HiVibe`, data);
        return order;
    }

    public async createHiVibe(hiVibe: IHiVibe): Promise<any> {
        const response = await HttpService.post(`${this.apiEndpointSP}/CreateHiVibe`, hiVibe);
        return response
    }

    public logErrorInConsole(errorArea: string, errorObj: any) {
        this.logError(errorObj.message);
        console.log(`Error message ${errorArea}: `, errorObj.message);
        console.log(`Error Stack Trace ${errorArea}: `, errorObj.stack);
    }

    private logError(msg: any) {
        if (console) {
            console.log(`%cERROR:`, "color:red;font-weight:bold;font-size:16px");
            console.log(msg);
        }
    }

    public extractErrorMessageFromErrorObject(error: any): string {

        let errorMessage = error.message ? error.message as string : error as string;

        if (errorMessage.indexOf("::>") >= 0) {
            try {
                let errorObj = JSON.parse(errorMessage.substring(errorMessage.indexOf("::>") + 3).trim());

                if (errorObj["odata.error"] && errorObj["odata.error"].message && errorObj["odata.error"].message.value)
                    errorMessage = errorObj["odata.error"].message.value;

            } catch (error) {
                errorMessage = errorMessage.substring(errorMessage.indexOf("::>") + 3).trim();
            }

        }

        return errorMessage;
    }


}