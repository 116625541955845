import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout/Layout';
import AuthProvider from './components/AuthenticationProvider/AuthenticationProvider';
import WelcomePage from './components/WelcomePage/Welcome';
import HiVibeNewForm from './components/HiVibeForms/HiVibeNewForm';
import HiVibeDisplayForm from './components/HiVibeForms/HiVibeDisplayForm';
import MockService from './dataprovider/MockService';
import DataProvider from './dataprovider/DataProvider';

import './Common.scss'
import { IApplicationSettings } from './Models';

export const PageURL = {
  SendHiVibe: "/SendArise",
  HiVibe: "/Arise",
}

export interface IAppState {
  applicationSettings?: IApplicationSettings;
}

export default class App extends React.Component<any, IAppState> {
  static displayName = App.name;

  // dataProvider = new MockService();
  dataProvider = new DataProvider();

  constructor(props: any) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const applicationSettings = await this.dataProvider.getApplicationSettings();

    this.setState({
      applicationSettings: applicationSettings
    });

    indexedDB.deleteDatabase("mgt-groups");
    indexedDB.deleteDatabase("mgt-people");
    indexedDB.deleteDatabase("mgt-photos");
    indexedDB.deleteDatabase("mgt-users");
  }

  render() {

    const applicationSettings = this.state.applicationSettings;

    if (applicationSettings) {
      const AuthenticatedCreationForm = AuthProvider(HiVibeNewForm, { applicationSettings: applicationSettings });
      const AuthenticatedDisplayForm = AuthProvider(HiVibeDisplayForm, { applicationSettings: applicationSettings });
      return (
        <Layout>
          <Route exact
            path='/'
            render={(props) => (
              <WelcomePage {...props as any} dataProvider={this.dataProvider} applicationSettings={applicationSettings} />
            )}
          />
          <Route exact
            path={PageURL.SendHiVibe}
            render={(props) => (
              <AuthenticatedCreationForm
                {...props}
                dataProvider={this.dataProvider}
              />
            )}
          />
          <Route
            path={`${PageURL.HiVibe}`}
            render={(props) => (
              <AuthenticatedDisplayForm
                {...props}
                dataProvider={this.dataProvider}
              />
            )}
          />
        </Layout>
      );
    }
    else {
      return (
        <Layout>
        </Layout>
      );
    }
  }
}
